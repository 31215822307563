<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container
    v-if="(version && version.workloadProperties && type !== 'codesys') || (version && type === 'codesys')"
    id="iiotBasicContainer"
    class="mt-8 ml-4"
    fluid
  >
    <v-form id="iiotBasicForm" ref="basicForm">
      <v-row>
        <v-col v-resize="onResize" cols="12" lg="4" class="pl-0 pt-0" :class="{ 'pr-12': isMarginVisible }">
          <span>{{ $t('workloadVersion.versionSpecificInfo') }}</span>
          <v-text-field
            id="iiotBasicName"
            v-model="version.name"
            :label="$t('workloadVersion.versionName')"
            :rules="[rules.required, rules.namePattern, rules.name_contains_only_spaces]"
            :maxlength="MAX_LENGTH_WORKLOAD_VERSION_NAME"
            autofocus
            validate-on-blur
            class="pt-6"
            :disabled="isReleased"
            @keydown.enter.prevent="triggerSubmit"
            @blur="checkForReleaseName"
            @focus="isVersionAndReleaseNameSame"
          />
          <v-text-field
            v-if="type === 'docker' && version.workloadProperties"
            id="iiotVersionDockerSpecificInfoContainerName"
            v-model="version.workloadProperties.container_name"
            :label="$t('workloadVersion.containerName')"
            :rules="[rules.required, rules.containerNamePattern, rules.name_contains_only_spaces]"
            :maxlength="MAX_LENGTH_NAME"
            validate-on-blur
            class="pt-10"
            :disabled="isReleased"
            @keydown.enter.prevent="triggerSubmit"
          />
          <v-select
            v-if="type === 'docker' && version.workloadProperties"
            id="iiotResourcesRestartPolicy"
            v-model="version.workloadProperties.restart_policy"
            data-cy="iiotResourcesRestartPolicy"
            attach
            :items="[
              { value: 'no', label: $t('workloadVersion.restartPolicyNo') },
              { value: 'on-failure', label: $t('workloadVersion.restartPolicyOnFailure') },
              { value: 'always', label: $t('workloadVersion.restartPolicyAlways') },
              {
                value: 'unless-stopped',
                label: $t('workloadVersion.restartPolicyUnlessStopped'),
              },
            ]"
            item-text="label"
            item-value="value"
            :label="$t('workloadVersion.restartPolicy')"
            class="pt-7"
            :disabled="isReleased"
            @change="selectorHasChanged"
          />
          <div class="pt-7">
            <span>{{ $t('workloadVersion.selector') }}</span>
            <v-combobox
              id="iiotBasicLabelsInput"
              ref="chips"
              v-model="selectedLabels"
              data-cy="iiotBasicLabelsInput"
              :items="transformedLabels"
              :cache-items="false"
              :search-input.sync="search"
              :single-line="true"
              :multiple="true"
              :dense="true"
              :menu-props="{ bottom: true, offsetY: true }"
              :label="$t('addUpdateNode.labels')"
              class="font-size mt-2"
              flat
              hide-no-data
              hide-details
              hide-selected
              chips
              @input="search = null"
              @change="validateLabel"
              @keydown="triggerSubmitLabel"
            >
              <template #selection="data">
                <v-chip label small>
                  <span class="mb-2 px-2">
                    {{ data.item }}
                  </span>
                  <v-icon
                    id="iiotBasicLabelsRemoveIcon"
                    data-cy="iiotBasicLabelsRemoveIcon"
                    small
                    @click="removeChip(data.item)"
                  >
                    mdi-close
                  </v-icon>
                </v-chip>
              </template>
              <template #append-item>
                <div v-intersect="endIntersect" />
              </template>
              <v-tooltip slot="append" top>
                <template #activator="{ on }">
                  <v-icon id="iiotBasicInfoIcon" class="cursor-help mt-3" color="primary" v-on="on"> info </v-icon>
                </template>
                <span>{{ $t('workloadVersion.labelInfo1') }}</span
                ><br />
                <span>{{ $t('nodes.addEditNode.labelInfo2') }}</span
                ><br />
                <span>{{ $t('nodes.addEditNode.labelInfo3') }}</span
                ><br />
                <span>{{ $t('nodes.addEditNode.labelInfo4') }}</span>
              </v-tooltip>
            </v-combobox>
          </div>
          <v-text-field
            id="iiotBasicReleaseName"
            v-model="version.releaseName"
            :label="$t('workloadVersion.releaseName')"
            :rules="[rules.required, rules.releaseNamePattern, rules.name_contains_only_spaces]"
            :maxlength="MAX_LENGTH_WORKLOAD_VERSION_RELEASE_NAME"
            validate-on-blur
            class="pt-15"
            :disabled="isReleased"
            @keydown.enter.prevent="triggerSubmit"
            @blur="setReleaseNameIfEmpty"
          />
          <div class="iiotBasicMarkAsRelease pr-14">
            <v-checkbox
              id="iiotBasicMarkAsRelease"
              v-model="version.released"
              :label="$t('workloadVersion.markAsReleased')"
              class="pt-5"
              :disabled="isReleased"
              @change="selectorHasChanged"
            />
          </div>
        </v-col>
        <div v-if="isMarginVisible" :class="{ 'ml-14': type === 'docker' || type === 'codesys' }">
          <v-divider vertical />
        </div>
        <v-col
          cols="12"
          lg="4"
          class="pt-0 pl-0 pr-14"
          :class="{
            'pl-10': isMarginVisible,
            'ml-16': isMarginVisible && (type === 'docker' || type === 'codesys'),
          }"
        >
          <version-upload-image
            ref="versionUploadImage"
            v-model="version.released"
            :type="type"
            :version="version"
            :is-released="isReleased"
            :show-warning="showWarning"
          />
          <snapshot
            v-if="
              type === 'vm' &&
              canAccess('UI_WORKLOAD:SNAPSHOT') &&
              version.workloadProperties &&
              version.workloadProperties.snapshot
            "
            ref="snapshot"
            type="snapshot"
            :version="version"
            :is-released="isReleased"
            @submit-event="triggerSubmit"
          />
        </v-col>
        <div v-if="isMarginVisible && type === 'vm'">
          <v-divider vertical />
        </div>
        <v-col v-if="type === 'vm'" cols="12" lg="3" class="pa-0 pb-4" :class="{ 'pl-11': isMarginVisible }">
          <span>{{ $t('workloadVersion.titleResources') }}</span>
          <v-text-field
            v-if="version.workloadProperties"
            id="iiotVersionVmSpecificInfoNumberOfVirtualCpus"
            v-model.number="version.workloadProperties.no_of_vCPUs"
            :label="$t('workloadVersion.numberOfVirtualCpu')"
            :rules="[rules.required, rules.cpuRange]"
            validate-on-blur
            :disabled="isReleased"
            @keydown.enter.prevent="triggerSubmit"
          />
          <limit-memory
            v-if="version.workloadProperties.memory"
            ref="limitMemory"
            type="vm"
            :version="version"
            :prop="version.workloadProperties.memory"
            :is-released="isReleased"
            @submit-event="triggerSubmit"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import {
  MAX_LENGTH_WORKLOAD_VERSION_NAME,
  MAX_LENGTH_WORKLOAD_VERSION_RELEASE_NAME,
  VALIDATION_REGEX,
  MAX_LENGTH_NAME,
  NUMBER_OF_VIRTUAL_CPU,
} from '@/constants';
import VersionUploadImage from '@/components/workloads/workloadVersionComponents/VersionUploadImage.vue';
import LimitMemory from '@/components/workloads/dockerVmComponents/limitMemory/LimitMemory.vue';
import Snapshot from '@/components/workloads/dockerVmComponents/snapshot/Snapshot.vue';

export default {
  components: {
    VersionUploadImage,
    LimitMemory,
    Snapshot,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    version: {
      type: Object,
      default: () => ({}),
    },
    isReleased: {
      type: Boolean,
    },
    showWarning: {
      type: Boolean,
    },
  },
  data: () => ({
    MAX_LENGTH_WORKLOAD_VERSION_NAME,
    MAX_LENGTH_WORKLOAD_VERSION_RELEASE_NAME,
    VALIDATION_REGEX,
    MAX_LENGTH_NAME,
    NUMBER_OF_VIRTUAL_CPU,
    selectedLabels: [],
    transformedLabels: [],
    selectedChip: '',
    search: null,
    isMarginVisible: false,
    lastPageOfLabels: 1,
    params: {
      search: '',
      itemsPerPage: 10,
      page: 1,
    },
    flag: false,
    shouldSubmit: true,
  }),
  computed: {
    rules() {
      return {
        required: (value) => (value !== 0 ? !!value || this.$t('workloadVersion.required') : true),
        namePattern: (value) =>
          VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(value) || this.$t('workloadVersion.workloadVersionNamePattern'),
        containerNamePattern: (value) =>
          VALIDATION_REGEX.CONTAINER_NAME_PATTERN.test(value) || this.$t('workloadVersion.containerNamePattern'),
        releaseNamePattern: (value) =>
          VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(value) ||
          this.$t('workloadVersion.workloadVersionReleaseNamePattern'),
        cpuRange: (value) =>
          (value >= NUMBER_OF_VIRTUAL_CPU.MIN_LENGTH && value <= NUMBER_OF_VIRTUAL_CPU.MAX_LENGTH) ||
          this.$t('workloadVersion.numberOfVirtualCpuRange'),
        name_contains_only_spaces: (value) =>
          !VALIDATION_REGEX.STRING_CONTAINS_ONLY_SPACES.test(value) ||
          this.$t('workloadDetail.wlNameContainOnlySpaces'),
      };
    },
    labelsList() {
      return this.$store.getters['labels/labelsList'];
    },
    count() {
      return this.$store.getters['labels/count'];
    },
  },
  watch: {
    labelsList() {
      if (!this.version.selectors) {
        this.transformLabels();
      }
    },
    async selectedLabels(value) {
      value.forEach(async (el) => {
        const words = el.split(':');
        let label = this.checkId(words[0], words[1]);
        while (this.params.page <= this.lastPageOfLabels && !label) {
          this.params.page += 1;
          // eslint-disable-next-line no-await-in-loop
          await this.$store.dispatch('labels/fetchLabels', this.params);
          label = this.checkId(words[0], words[1]);
        }
        if (!label) {
          await this.removeChip(this.selectedChip);
          await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'workloadVersion.unknownSelector',
            color: 'red',
            showClose: true,
          });
        }
      });
      this.version.selectors = await this.transformLabelsToOriginal();
    },
    search(val) {
      if (val) {
        this.selectedChip = val;
        this.params.search = val;
        this.params.page = 1;
        this.$store.dispatch('labels/fetchLabels', this.params);
      }
    },
    // eslint-disable-next-line func-names
    'version.releaseName': function () {
      this.$nextTick(() => {
        this.$refs.basicForm.$el.dispatchEvent(new Event('input'));
      });
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('labels/fetchLabels', this.params);
      this.lastPageOfLabels = Math.ceil(this.count / 10);
      this.transformLabels();
    } catch (e) {
      this.$log.debug(e);
    }
  },
  methods: {
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        if (this.params.page >= this.lastPageOfLabels) {
          return;
        }
        // eslint-disable-next-line no-plusplus
        this.params.page += 1;
        this.transformLabels();
      }
    },
    async removeChip(item) {
      const index = this.selectedLabels.indexOf(item);
      if (index >= 0) {
        this.selectedLabels.splice(index, 1);
      }
      this.$refs.basicForm.$el.dispatchEvent(new Event('input'));
    },
    transformLabels() {
      // eslint-disable-next-line array-callback-return
      this.labelsList.map((el) => {
        this.transformedLabels.push(`${el.key}:${el.value}`);
      });
      if (this.version && this.version.selectors && this.version.selectors.length) {
        this.selectedLabels = [];
        // eslint-disable-next-line array-callback-return
        this.version.selectors.map((el) => {
          this.selectedLabels.push(`${el.key}:${el.value}`);
        });
      }
    },
    transformLabelsToOriginal() {
      const newSelectedLabels = [...this.selectedLabels];
      return newSelectedLabels.map((el) => {
        const words = el.split(':');
        const label = this.checkId(words[0], words[1]);
        return {
          _id: label?._id,
          key: words[0],
          value: words[1],
        };
      });
    },
    checkId(key, value) {
      return this.labelsList.find((label) => label.key === key && label.value === value);
    },
    async validateLabel(label) {
      const words = label[label.length - 1] ? label[label.length - 1].split(':') : this.selectedChip.split(':');
      this.$refs.basicForm.$el.dispatchEvent(new Event('input'));
      if (this.transformedLabels.includes(label[label.length - 1]) || !label.length) {
        return;
      }
      if (!VALIDATION_REGEX.LABEL_REGEX.test(words[0])) {
        await this.showToast(this.selectedChip, 'invalidLabel');
      }
      if (this.labelsList.some((el) => el.key.toLowerCase() === words[0].toLowerCase())) {
        await this.showToast(label[label.length - 1], 'duplicateKey');
      }
      if (words.length !== 2 || words[0] === '' || words[1] === '') {
        await this.showToast(this.selectedChip, 'wrongFormatForLabel');
      }
    },
    async showToast(chip, text) {
      await this.removeChip(chip);
      await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: `nodes.addEditNode.${text}`,
        color: 'red',
        showClose: true,
      });
    },
    triggerSubmit() {
      if (this.$refs.basicForm.validate()) {
        this.$emit('submit-event');
      }
    },
    triggerSubmitLabel(event) {
      if (event.key !== 'Enter') {
        this.shouldSubmit = false;
        return;
      }
      if (this.shouldSubmit && !this.search && this.$refs.basicForm.validate()) {
        this.$emit('submit-event');
      }
      this.shouldSubmit = true;
    },
    onResize() {
      this.isMarginVisible = window.innerWidth > 1264;
    },
    checkForReleaseName() {
      if (this.flag || !this.version.releaseName) {
        this.version.releaseName = this.version.name;
        this.$nextTick(() => {
          this.$refs.basicForm.validate();
        });
        this.flag = false;
      }
    },
    isVersionAndReleaseNameSame() {
      this.flag = this.version.releaseName === this.version.name;
    },

    setReleaseNameIfEmpty() {
      if (!this.version.releaseName) {
        this.version.releaseName = this.version.name;
        this.$nextTick(() => {
          this.$refs.basicForm.validate();
        });
      }
    },
    selectorHasChanged() {
      this.$refs.basicForm.$el.dispatchEvent(new Event('input'));
    },
  },
};
</script>
<style scoped>
.v-divider--vertical {
  min-height: 250px;
}
.iiotBasicMarkAsRelease {
  max-width: 250px;
}
#iiotBasicInfoIcon {
  padding-right: 2px;
}
</style>
