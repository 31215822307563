<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container id="iiotDockerContainer" fill-height fluid class="pl-0 pt-0">
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12" lg="11">
            <div v-if="!isWorkloadEmpty" class="title">
              <h1>
                {{
                  isNewWl
                    ? $t('workloadVersion.newWl')
                    : isUpdate
                      ? $t('workloadVersion.editWl')
                      : isCloneWl
                        ? $t('workloadVersion.cloneWl')
                        : $t('workloadVersion.title')
                }}
              </h1>
              <v-divider />
            </div>
          </v-col>
          <v-col v-if="version.errorMessage && version.errorMessage !== 'nerve_workload_cancelled'" cols="12" lg="8">
            <div>
              <v-alert id="iiotDockerAlert" icon="mdi-alert" border="left" color="red" text class="docker-alert">
                {{ $t('errorMessages.WORKLOAD_DOCKER_IMG_DOWNLOAD_ERROR') + ': "' + version.errorMessage + '"' }}
              </v-alert>
            </div>
          </v-col>
        </v-row>
        <v-form
          v-if="!isWorkloadEmpty && isVersionReady"
          id="iiotDockerForm"
          ref="form"
          data-cy="iiotDockerForm"
          @submit.prevent="submitForm"
        >
          <v-col class="pl-0 mb-10">
            <v-row>
              <v-col>
                <v-tabs
                  v-model="tab"
                  class="tab-text-transform"
                  next-icon="mdi-arrow-right-bold-box-outline"
                  prev-icon="mdi-arrow-left-bold-box-outline"
                  show-arrows
                >
                  <v-tab
                    id="iiotDockerBasicTab"
                    :key="basic"
                    data-cy="iiotDockerBasicTab"
                    autofocus
                    @click="tabChanged(0)"
                  >
                    <h4 v-if="isBasicFormValid">{{ $t('workloadVersion.basic') }}</h4>
                    <h4 v-if="!isBasicFormValid" class="required">
                      {{ $t('workloadVersion.requiredBasic') }}
                    </h4>
                    <v-tooltip v-if="showWarning" bottom>
                      <template #activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-img
                            id="iiotDockerRegistryWarningIcon"
                            src="/img/warning.svg"
                            max-height="17px"
                            max-width="17px"
                            class="ml-2 mt-1"
                          />
                        </div>
                      </template>
                      <span>
                        {{ $t('workloadVersion.passwordIsRequiredForRegistry') }}
                      </span>
                    </v-tooltip>
                  </v-tab>
                  <v-tab
                    id="iiotDockerNetworkingTab"
                    :key="networking"
                    data-cy="iiotDockerNetworkingTab"
                    @click="tabChanged(1)"
                  >
                    <h4 v-if="isNetworkingFormValid">{{ $t('workloadVersion.networking') }}</h4>
                    <h4 v-if="!isNetworkingFormValid" class="required">
                      {{ $t('workloadVersion.requiredNetworking') }}
                    </h4>
                  </v-tab>
                  <v-tab
                    id="iiotDockerEnvironmentVariablesTab"
                    :key="environmentVariables"
                    data-cy="iiotDockerEnvironmentVariablesTab"
                    @click="tabChanged(2)"
                  >
                    <h4 v-if="isEnvironmentVariableFormValid">
                      {{ $t('workloadVersion.environmentVariables') }}
                    </h4>
                    <h4 v-if="!isEnvironmentVariableFormValid" class="required">
                      {{ $t('workloadVersion.requiredEnvironmentVariables') }}
                    </h4>
                  </v-tab>
                  <v-tab id="iiotDockerVolumesTab" :key="volumes" data-cy="iiotDockerVolumesTab" @click="tabChanged(3)">
                    <h4 v-if="isVolumesValid">
                      {{ $t('workloadVersion.volumes') }}
                    </h4>
                    <h4 v-if="!isVolumesValid" class="required">
                      {{ $t('workloadVersion.requiredVolumes') }}
                    </h4>
                  </v-tab>
                  <v-tab
                    id="iiotDockerResourceTab"
                    :key="resources"
                    data-cy="iiotDockerResourceTab"
                    @click="tabChanged(4)"
                  >
                    <h4 v-if="isResourcesFormValid">
                      {{ $t('workloadVersion.resources') }}
                    </h4>
                    <h4 v-if="!isResourcesFormValid" class="required">
                      {{ $t('workloadVersion.requiredResources') }}
                    </h4>
                  </v-tab>

                  <v-tab
                    id="iiotDockerRemoteConnectionTab"
                    :key="remoteConnections"
                    data-cy="iiotDockerRemoteConnectionTab"
                    @click="tabChanged(5)"
                  >
                    <h4>{{ $t('workloadVersion.remoteConnections') }}</h4>
                    <v-tooltip v-if="showRcWarning" bottom>
                      <template #activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-img
                            id="iiotDockerRemoteConnectionsWarningIcon"
                            src="/img/warning.svg"
                            max-height="17px"
                            max-width="17px"
                            class="ml-2 mt-1"
                          />
                        </div>
                      </template>
                      <span>
                        {{ $t('remoteConnection.passwordIsRequiredForVnc') }}
                      </span>
                    </v-tooltip>
                  </v-tab>
                </v-tabs>
                <v-divider />
              </v-col>
            </v-row>
            <v-tabs-items v-model="tab">
              <v-tab-item :key="basic" eager>
                <basic
                  ref="basic"
                  type="docker"
                  :version="version"
                  :is-released="isReleased"
                  :show-warning="showWarning"
                  @submit-event="submitEventHandler"
                />
              </v-tab-item>
              <v-tab-item :key="networking" eager>
                <networking
                  ref="networking"
                  :version="version"
                  type="docker"
                  :is-released="isReleased"
                  @submit-event="submitEventHandler"
                />
              </v-tab-item>
              <v-tab-item :key="environmentVariables" eager>
                <environment-variable
                  ref="environmentVariable"
                  :version="version"
                  :is-released="isReleased"
                  @submit-event="submitEventHandler"
                />
              </v-tab-item>
              <v-tab-item :key="volumes" eager>
                <volumes
                  ref="volumes"
                  :version="version"
                  :is-released="isReleased"
                  @submit-event="submitEventHandler"
                />
              </v-tab-item>
              <v-tab-item :key="resources" eager>
                <resources
                  ref="resources"
                  :version="version"
                  :is-released="isReleased"
                  @submit-event="submitEventHandler"
                />
              </v-tab-item>
              <v-tab-item :key="remoteConnections" eager>
                <v-col cols="12" lg="4">
                  <remote-connection-list
                    id="iiotDockerList"
                    :model="version"
                    :version-type="'docker'"
                    :initial-number-of-connections="initialNoOfConnections"
                    :show-warning="showRcWarning"
                  />
                </v-col>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col class="pl-0 pr-0">
            <v-row>
              <v-col>
                <div class="fixed-buttons">
                  <nerve-button
                    id="iiotDockerCancelButton"
                    :text="$t('baseForm.cancelBtn')"
                    type-of-btn="cancel"
                    size="normal"
                    class="mr-5 ml-0"
                    @click-event="cancel()"
                  />
                  <nerve-button
                    v-if="
                      (!isUpdate && !canAccess('UI_WORKLOAD:VERSION_EDIT')) ||
                      (isUpdate && canAccess('UI_WORKLOAD:VERSION_EDIT')) ||
                      (!isUpdate && canAccess('UI_WORKLOAD:VERSION_CREATE'))
                    "
                    id="iiotDockerSaveButton"
                    :disabled="!isSaveEnabled || !isValid"
                    :text="$t('baseForm.saveBtn')"
                    type-of-btn="action"
                    size="normal"
                    type="submit"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { NerveButton } from 'nerve-ui-components';
import RemoteConnectionList from '@/components/remote-connection/RemoteConnectionList.vue';
import Basic from '@/components/workloads/workloadVersionComponents/Basic.vue';
import Networking from '@/components/workloads/workloadVersionComponents/Networking.vue';
import EnvironmentVariable from '@/components/workloads/workloadVersionComponents/EnvironmentVariable.vue';
import Volumes from '@/components/workloads/workloadVersionComponents/Volumes.vue';
import Resources from '@/components/workloads/workloadVersionComponents/Resources.vue';

export default {
  components: {
    RemoteConnectionList,
    Basic,
    Networking,
    EnvironmentVariable,
    Volumes,
    Resources,
    NerveButton,
  },
  props: {
    version: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isUpdate: false,
    tab: null,
    basic: 0,
    networking: 1,
    environmentVariables: 2,
    volumes: 3,
    resources: 4,
    remoteConnections: 5,
    workloadId: '',
    isBasicFormValid: true,
    isNetworkingFormValid: true,
    isEnvironmentVariableFormValid: true,
    isResourcesFormValid: true,
    isVolumesValid: true,
    availableTabs: [0, 1, 2, 3, 4, 5],
    createAvailableTabs: [0, 1, 2, 3, 4],
    numberOfTabs: 0,
    versionId: '',
    isWorkloadEmpty: false,
    isReleased: false,
    isValid: false,
    isVersionReady: false,
    isNewWl: false,
    isCloneWl: false,
    initialNoOfConnections: 0,
    oldUsername: '',
  }),
  computed: {
    workload() {
      return this.$store.getters['workloads/getWorkload'];
    },
    getNameAndDesc() {
      return this.$store.getters['workloads/getNameAndDesc'];
    },
    isSaveEnabled() {
      return this.$store.getters['workloads/isSaveEnabled'];
    },
    getFormChangedFlag() {
      return this.$store.getters['workloads/getFormChangedFlag'];
    },
    selectedDeployedWorkloadVersionTimestamp() {
      return this.$store.getters['workloads/getSelectedDeployedWorkloadVersionTimestamp'];
    },
    getIsTimestampFromActiveRC() {
      return this.$store.getters['workloads/getIsTimestampFromActiveRC'];
    },
    showWarning() {
      if (!this.oldUsername) {
        return false;
      }
      const versionHasUsernameButNoPassword =
        this.version?.workloadProperties?.auth_credentials &&
        this.version.workloadProperties.auth_credentials.username &&
        !this.version.workloadProperties.auth_credentials.password;
      return (
        this.isNewWl &&
        this.workload.versions?.length !== 0 &&
        !!versionHasUsernameButNoPassword &&
        this.version.dockerFileOption === 'path'
      );
    },
    showRcWarning() {
      return (
        (this.isNewWl || this.isCloneWl) &&
        !!this.version.remoteConnections?.find(
          (rc) => (rc.connection?.toLowerCase() === 'vnc' && !rc.password) || (rc.username && !rc.password),
        )
      );
    },
  },
  watch: {
    isSaveEnabled(newValue) {
      this.$nextTick(async () => {
        if (newValue) {
          this.isValid = await this.isDockerFormValid();
        }
      });
    },
    getFormChangedFlag() {
      this.formChanged();
      this.$nextTick(() => {
        this.$refs.basic?.$refs.versionUploadImage?.$refs.uploadFileDockerImage?.$refs[
          this.version.dockerFileOption === 'path' ? 'fromRegistry' : 'uploadFileDocker'
        ]?.$refs[
          this.version.dockerFileOption === 'path' ? 'fromRegistryForm' : 'uploadFileDockerForm'
        ]?.$el.addEventListener('input', () => {
          this.formChanged();
        });
      });
    },
  },
  async created() {
    try {
      const route = window.location.pathname.split('/');
      this.versionId = route[route.length - 2];
      if (this.versionId === 'clone') {
        this.isCloneWl = true;
      }
      this.isUpdate = this.versionId !== 'new' && this.versionId !== 'clone';
      this.workloadId = route[route.length - 4];
      this.numberOfTabs = this.isUpdate ? 5 : 4;
      const tab = Number(this.$route.query.tab);
      const tabs = this.isUpdate ? this.availableTabs : this.createAvailableTabs;
      if (tabs.includes(tab)) {
        this.tabChanged(Number(this.$route.query.tab));
      } else {
        this.tabChanged(0);
      }
      this.version.released = false;
      if (this.versionId !== 'clone') {
        await this.$store.dispatch('workloads/get_workload_by_id', this.workloadId);
      } else {
        this.version.cloneOf = this.version.id;
        if (Object.keys(this.version).length === 0 && this.version.constructor === Object) {
          this.cancel();
        }
        if (!this.version.workloadProperties) {
          this.cancel();
        }

        this.$store.dispatch('workloads/set_save_enabled', this.version);

        this.isVersionReady = true;
        // Add listeners for changes on all forms
        this.$nextTick(() => {
          this.addListeners();
        });
        return;
      }
      this.workload.type = 'docker';
      await this.$store.dispatch('workloads/get_version_by_id', {
        id: this.versionId,
        isUpdate: this.isUpdate,
      });
      this.oldUsername = this.version?.workloadProperties?.auth_credentials?.username || '';
      const timeOfUpdate = this.version.updatedAt && new Date(this.version.updatedAt).getTime();
      if (
        this.selectedDeployedWorkloadVersionTimestamp &&
        timeOfUpdate &&
        this.selectedDeployedWorkloadVersionTimestamp < timeOfUpdate
      ) {
        this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: this.getIsTimestampFromActiveRC
            ? 'workloadVersion.versionHasChangedSinceRc'
            : 'workloadVersion.versionHasChanged',
          color: 'primary',
          showClose: true,
        });
      }
      this.isVersionReady = true;
      if (this.version.errorMessage) {
        this.$store.dispatch('workloads/set_save_enabled');
      }
      if (this.versionId === 'new') {
        this.version.released = false;
        this.isNewWl = true;
      }
      this.isReleased = this.version.released;

      // Add listeners for changes on all forms
      this.$nextTick(() => {
        this.addListeners();
      });

      await this.$store.dispatch('remote-connection/fetch', this.version);
      this.initialNoOfConnections = this.$store.getters['remote-connection/list'].length;

      if (this.getNameAndDesc.name === '' && this.workload.name === '') {
        this.cancel();
      }
      if (this.getNameAndDesc.name === '' && this.workload.name !== '') {
        return;
      }
      if (this.getNameAndDesc.name !== '' && this.workload.name === '') {
        this.workload.name = this.getNameAndDesc.name;
        this.workload.description = this.getNameAndDesc.description;
        return;
      }
      if (this.workload.name !== this.getNameAndDesc.name && this.workload.name !== '') {
        this.workload.name = this.getNameAndDesc.name;
        this.workload.description = this.getNameAndDesc.description;
      }
    } catch (e) {
      this.$log.debug(e);
    }
  },
  async updated() {
    await this.$store.dispatch('remote-connection/fetch', this.version);
  },
  beforeDestroy() {
    this.$store.dispatch('workloads/disable_save_button');
  },
  methods: {
    async submitForm() {
      if (!this.isDockerFormValid()) {
        return;
      }
      if (this.version.released) {
        this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
          title: 'workloadVersion.markAsReleasedMessageTitle',
          subTitle: 'workloadVersion.markAsReleasedMessageText',
          callback: async () => {
            this.createWorkload();
          },
        });
      } else {
        this.createWorkload();
      }
    },

    async createWorkload() {
      const isAddVersionOnExistingWl = !this.isUpdate && this.version._id;
      if (isAddVersionOnExistingWl) {
        delete this.version._id;
      }
      if (!this.version.workloadProperties.limit_memory.value) {
        this.version.workloadProperties.limit_memory = {};
      }
      if (
        !this.version.workloadProperties.auth_credentials.username &&
        !this.version.workloadProperties.auth_credentials.password
      ) {
        this.version.workloadProperties.auth_credentials = {};
      }

      const fd = new FormData();
      if (this.version.dockerFileOption === 'file') {
        this.version.dockerFilePath = '';
      }
      if (!this.workload.versions.length && this.version.dockerFileOption === 'file') {
        this.version.files = {
          0: {
            originalName: this.version.files[0].originalName,
          },
        };
      }
      this.workload.versions = [this.version];
      this.workload.type = 'docker';
      fd.append('data', JSON.stringify(this.workload));
      fd.append(
        'file',
        this.version.dockerFileOption === 'path'
          ? JSON.stringify({})
          : this.$store.getters['workloads/getUploadedFile'],
      );
      try {
        if (!this.workload._id) {
          await this.$store.dispatch('workloads/create_workload', fd);
        } else {
          await this.$store.dispatch('workloads/update_version', fd);
        }
        await this.$store.dispatch('workloads/save_uploaded_file', {});
        this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
      } catch (e) {
        this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
        return;
      }
      if (this.workloadId === 'new') {
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'workloadVersion.successfullyCreated',
          color: 'green',
          showClose: true,
        });
        this.$router.push({
          name: 'Workloads',
        });
        return;
      }
      await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: 'workloadDetail.successfullyUpdate',
        color: 'green',
        showClose: true,
      });
      this.$router.push({
        name: 'Add edit workload',
        params: { id: this.workloadId, type: 'docker' },
      });
    },

    async isDockerFormValid() {
      this.isBasicFormValid =
        (await this.$refs.basic.$refs.basicForm.validate()) &&
        (await this.$refs.basic.$refs.versionUploadImage.$refs.uploadFileDockerImage.$refs[
          this.version.dockerFileOption === 'path' ? 'fromRegistry' : 'uploadFileDocker'
        ].$refs[this.version.dockerFileOption === 'path' ? 'fromRegistryForm' : 'uploadFileDockerForm'].validate());
      this.isNetworkingFormValid =
        (await this.$refs.networking.$refs.portsForm.$refs.portsForm.validate()) &&
        (await this.$refs.networking.$refs.dockerNetwork.$refs.dockerNetworkForm.validate());
      this.isEnvironmentVariableFormValid =
        await this.$refs.environmentVariable.$refs.environmentVariableForm.$refs.envVariableForm.validate();
      this.isVolumesValid = await this.$refs.volumes.$refs.volumesForm.$refs.volumesForm.validate();
      this.isResourcesFormValid =
        (await this.$refs.resources.$refs.resources.validate()) &&
        (await this.$refs.resources.$refs.limitMemory.$refs.limitMemoryForm.validate());
      return (
        this.isBasicFormValid &&
        this.isNetworkingFormValid &&
        this.isEnvironmentVariableFormValid &&
        this.isVolumesValid &&
        this.isResourcesFormValid &&
        !this.showWarning
      );
    },
    async tabChanged(tab) {
      this.tab = tab;
      await this.$router
        .push({
          name: 'Add edit workload version',
          query: {
            tab,
          },
        })
        .catch(() => {});
    },
    cancel() {
      this.$store.dispatch('workloads/save_uploaded_file', {});
      this.$router.push({
        name: 'Add edit workload',
        params: { id: this.workloadId, type: 'docker' },
      });
    },
    submitEventHandler() {
      this.submitForm();
    },
    async formChanged() {
      this.isValid = await this.isDockerFormValid();
      if (this.isValid) {
        this.$store.dispatch('workloads/set_save_enabled', this.version);
        return;
      }
      this.$store.dispatch('workloads/disable_save_button');
    },
    addListeners() {
      this.$refs.basic?.$refs.basicForm?.$el.addEventListener('input', () => {
        this.formChanged();
      });
      this.$refs.basic?.$refs.versionUploadImage?.$refs.uploadFileDockerImage?.$refs[
        this.version.dockerFileOption === 'path' ? 'fromRegistry' : 'uploadFileDocker'
      ]?.$refs[
        this.version.dockerFileOption === 'path' ? 'fromRegistryForm' : 'uploadFileDockerForm'
      ]?.$el.addEventListener('input', () => {
        this.formChanged();
      });
      this.$refs.networking?.$refs.portsForm?.$refs.portsForm?.$el.addEventListener('input', () => {
        this.formChanged();
      });
      this.$refs.networking?.$refs.dockerNetwork?.$refs.dockerNetworkForm?.$el.addEventListener('input', () => {
        this.formChanged();
      });

      this.$refs.environmentVariable?.$refs.environmentVariableForm?.$refs.envVariableForm?.$el.addEventListener(
        'input',
        () => {
          this.formChanged();
        },
      );
      this.$refs.volumes?.$refs.volumesForm?.$refs.volumesForm?.$el.addEventListener('input', () => {
        this.formChanged();
      });
      this.$refs.resources?.$refs.resources?.$el.addEventListener('input', () => {
        this.formChanged();
      });
      this.$refs.resources?.$refs.limitMemory?.$refs.limitMemoryForm?.$el.addEventListener('input', () => {
        this.formChanged();
      });
    },
  },
};
</script>

<style scoped>
.fixed-buttons {
  position: fixed !important;
  bottom: 0px;
  background: white;
  z-index: 10;
  padding-bottom: 10px;
  padding-right: 10px;
}
.required {
  color: var(--v-error-base) !important;
}
.docker-alert {
  font-size: 14px;
}
</style>
