<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container id="iiotVolumesContainer" class="mt-8 ml-4">
    <v-form id="iiotVolumesPageForm" ref="volumes">
      <v-row>
        <v-col cols="12" lg="8" class="pl-0 pt-0 pr-5">
          <span>{{ $t('workloadVersion.dockerVolumes') }}</span> <br />
          <volumes
            v-if="version.workloadProperties"
            ref="volumesForm"
            :prop="version.workloadProperties.docker_volumes"
            :version="version"
            :is-released="isReleased"
            @submit-event="triggerSubmit"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Volumes from '@/components/workloads/dockerVmComponents/volumes/Volumes.vue';

export default {
  components: { Volumes },

  props: {
    version: {
      type: Object,
      default: null, // Set a default value for the 'version' prop
    },
    isReleased: {
      type: Boolean,
    },
  },
  methods: {
    triggerSubmit() {
      this.$emit('submit-event');
    },
  },
};
</script>
