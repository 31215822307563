<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container
    id="iiotSnapshotContainer"
    v-resize="onResize"
    ml-0
    :class="{ 'margin-bottom': isSnapshotMarginVisible }"
  >
    <v-form id="iiotSnapshotForm" ref="snapshotForm">
      <v-checkbox
        id="iiotSnapshotEnableCheckbox"
        v-model="version.workloadProperties.snapshot.enabled"
        :label="$t('workloadVersion.enableSnapshot')"
        :disabled="disableCheckbox || isReleased"
        @change="enableSnapshot()"
      />
      <v-row class="title d-inline-flex">
        <v-col cols="10" class="pa-0">
          <limit-memory
            v-if="version.workloadProperties.snapshot.enabled"
            ref="limitMemory"
            type="snapshot"
            :prop="version.workloadProperties.snapshot"
            :version="version"
            :is-released="isReleased"
            @submit-event="triggerSubmit"
          />
        </v-col>
        <v-col v-if="version.workloadProperties.snapshot.enabled" cols="2" class="mt-4 pl-0">
          <v-tooltip slot="append" bottom>
            <template #activator="{ on }">
              <v-icon class="cursor-help" color="primary" v-on="on"> info </v-icon>
            </template>
            <span>{{ $t('workloadVersion.snapshotInfoMessage1') }}</span
            ><br />
            <span>{{ $t('workloadVersion.snapshotInfoMessage2') }}</span
            ><br />
            <span>{{ $t('workloadVersion.snapshotInfoMessage3') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <span v-if="!isWarning">{{ $t('workloadVersion.snapshotNote') }} </span>
        <span v-if="isWarning" class="new-version-snapshot-option-warning-message"
          >{{ $t('workloadVersion.snapshotWarning') }}
        </span>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import LimitMemory from '@/components/workloads/dockerVmComponents/limitMemory/LimitMemory.vue';

export default {
  components: { LimitMemory },
  props: {
    version: {
      type: Object,
      default: () => ({}),
    },
    isReleased: {
      type: Boolean,
    },
  },
  data: () => ({
    type: 'snapshot',
    isFilesUpdated: false,
    isOptionUpdated: false,
    isWarning: false,
    isSnapshotEnabledAndImageIsNotQCOW2: false,
    disableCheckbox: false,
    isSnapshotMarginVisible: false,
  }),
  computed: {
    version1() {
      return this.$store.getters['workloads/getVersion'].files;
    },
  },

  watch: {
    version1: {
      handler() {
        const isQcow2ImageFile = this.isQcow2Image();

        if (this.isFilesUpdated) {
          // Files changed by user - removing warning message(red text)
          this.isWarning = false;

          this.disableCheckbox = !isQcow2ImageFile;
        }

        // On first load
        if (!this.isFilesUpdated) {
          this.isFilesUpdated = true;
          // Is not qcow2 image and the snapshot option is not selected, checkbox should be disabled
          this.disableCheckbox =
            !isQcow2ImageFile && !(this.version && this.version.workloadProperties.snapshot.enabled);
        }
      },
      deep: true,
    },
    // eslint-disable-next-line func-names
    'version.workloadProperties.snapshot.enabled': function (newValue) {
      if (!newValue) {
        this.version.workloadProperties.snapshot.unit = 'GB';
      }
      if (this.isOptionUpdated) {
        this.isWarning = false;

        const isQcow2ImageFile = this.isQcow2Image();
        // Image is not qcow2 and user unselect snapshot option - checkbox should be disabled
        this.disableCheckbox = !isQcow2ImageFile && !newValue;
      }
      if (!this.isOptionUpdated) {
        this.isOptionUpdated = true;
      }
    },
  },
  mounted() {
    this.isFormValid = this.validationForm();
    // What to display a Warning or Info message?
    this.isSnapshotEnabledAndImageIsNotQCOW2 =
      !this.isQcow2Image() &&
      this.version &&
      this.version.workloadProperties &&
      this.version.workloadProperties.snapshot &&
      this.version.workloadProperties.snapshot.enabled;
    this.isWarning = this.isSnapshotEnabledAndImageIsNotQCOW2;
    this.disableCheckbox = !this.isQcow2Image();
  },
  updated() {
    this.isFormValid = this.validationForm();
  },
  methods: {
    validationForm() {
      return this.$refs.snapshotForm ? this.$refs.snapshotForm.validate() : false;
    },
    isCompatibleFileType(fileName) {
      if (!fileName) {
        return false;
      }
      const ext = fileName.substring(fileName.lastIndexOf('.'));
      return ['.qcow2', '.zip'].includes(ext) || fileName.endsWith('.qcow2.gz');
    },
    isQcow2Image() {
      const { files } = this.version;

      // Updating version - files from DB
      if (Array.isArray(files) && files.length) {
        const found = files.find((file) => {
          // User select a new file
          if (!file.type && file.originalName && this.isCompatibleFileType(file.originalName)) {
            return file;
          }
          // File from DB
          if (file.type === '.qcow2' || file.containFileType === '.qcow2') {
            return file;
          }
          return null;
        });
        return !!found;
      }
      // New workload
      if (files instanceof Object) {
        const values = Object.values(files);
        if (!values.length) {
          return true; // No files yet
        }
        const found = values.find((file) => this.isCompatibleFileType(file.originalName));
        return !!found;
      }
      // There are no files yet
      return true;
    },
    onResize() {
      this.isSnapshotMarginVisible = window.innerWidth > 1264;
    },
    enableSnapshot() {
      this.$refs.snapshotForm.$el.dispatchEvent(new Event('input'));
      if (!this.version.workloadProperties.snapshot.enabled) {
        delete this.version.workloadProperties.snapshot.value;
        delete this.version.workloadProperties.snapshot.unit;
        return;
      }
      this.$refs.snapshotForm.$el.dispatchEvent(new Event('input'));
      this.version.workloadProperties.snapshot.unit = 'GB';
    },
    triggerSubmit() {
      if (this.$refs.snapshotForm.validate()) {
        this.$emit('submit-event');
      }
    },
  },
};
</script>

<style scoped>
.new-version-snapshot-option-warning-message {
  color: red;
}
.margin-bottom {
  margin-bottom: 90px !important;
}
</style>
