<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div id="iiotVersionUploadImageContainer" ref="versionUploadImage">
    <div v-if="type === 'docker'">
      <docker-image
        ref="uploadFileDockerImage"
        :version="version"
        :is-released="isReleased"
        :type="type"
        :show-warning="showWarning"
      />
    </div>
    <div v-if="type === 'vm'">
      <upload-file-vm
        v-if="version.workloadProperties.snapshot"
        ref="uploadFileVm"
        :version="version"
        :is-released="isReleased"
      />
    </div>
    <div v-if="type === 'codesys'">
      <upload-file-codesys ref="uploadFileCodesys" :version="version" :is-released="isReleased" />
    </div>
  </div>
</template>

<script>
import UploadFileVm from '@/components/workloads/dockerVmComponents/upload-file/UploadFileVm.vue';
import UploadFileCodesys from '@/components/workloads/dockerVmComponents/upload-file/UploadFileCodesys.vue';
import DockerImage from '@/components/workloads/dockerVmComponents/upload-file/DockerImage.vue';

export default {
  components: { UploadFileVm, UploadFileCodesys, DockerImage },
  props: {
    type: {
      type: String,
      default: '',
    },
    version: {
      type: Object,
      default: () => ({}),
    },
    isReleased: {
      type: Boolean,
    },
    showWarning: {
      type: Boolean,
    },
  },
};
</script>
