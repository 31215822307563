<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container id="iiotNetworkInterfaceContainer" data-cy="iiotNetworkInterfaceContainer">
    <v-form id="iiotNetworkInterfaceForm" ref="networkInterfaceForm" data-cy="iiotNetworkInterfaceForm">
      <v-row v-for="(variable, index) in prop" :key="index">
        <v-col cols="3" lg="4" class="pa-0 mb-12">
          <v-select
            :id="`iiotActiveNetworkInterfaceSelectType_${index}`"
            ref="select"
            v-model="variable.type"
            :data-cy="`iiotActiveNetworkInterfaceSelectType_${index}`"
            :label="$t('workloadVersion.interface')"
            :items="[
              { value: 'Bridged', text: $t('workloadVersion.interfaceType.bridged') },
              { value: 'NAT', text: $t('workloadVersion.interfaceType.nat') },
            ]"
            :disabled="isReleased"
            attach
            @change="onSelectedInterfaceChange"
          />
        </v-col>
        <v-col cols="8" lg="7" class="pa-0 pl-4">
          <v-row class="ma-0">
            <v-text-field
              :id="`iiotNetworkInterfaceInput_${index}`"
              v-model="variable.interface"
              :data-cy="`iiotNetworkInterfaceInput_${index}`"
              validate-on-blur
              :rules="[rules.required, rules.pattern, rules.duplicate]"
              :placeholder="$t('workloadVersion.interfacePlaceholder')"
              :disabled="isReleased"
              @keydown.enter.prevent="triggerSubmit"
            />
          </v-row>
        </v-col>
        <v-col cols="1" class="mt-4 pa-0 pl-3">
          <v-btn
            :id="`iiotNetworkInterfaceRemoveButton_${index}`"
            :data-cy="`iiotNetworkInterfaceRemoveButton_${index}`"
            fab
            x-small
            dark
            class="primary-tttech-background-btn"
            :disabled="isReleased"
            @click="removeNetworkInterface(index)"
          >
            <v-icon dark> mdi-minus </v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="variable.type === 'NAT'" cols="12" class="pl-0 pr-0 mb-3">
          <ports
            :id="`iiotPorts_${index}`"
            ref="portsForm"
            :prop="variable.port_forwards"
            type="vm"
            :version="version"
            :interface="variable.interface"
            :is-released="isReleased"
            @submit-event="triggerSubmit"
            @ports-event="receivePorts"
          ></ports>
        </v-col>
      </v-row>
      <v-row v-if="isFormValid">
        <div class="mb-1 ml-1">
          <v-btn
            id="iiotNetworkInterfaceNewButton"
            data-cy="iiotNetworkInterfaceNewButton"
            fab
            x-small
            dark
            class="primary-tttech-background-btn"
            :disabled="isReleased"
            @click="addNewNetworkInterface()"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </div>
        <span class="mt-1 ml-3">
          {{ $t('workloadVersion.newNetworkInterface') }}
        </span>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { VALIDATION_REGEX } from '@/constants';
import Ports from '@/components/workloads/dockerVmComponents/ports/Ports.vue';

export default {
  components: { Ports },
  props: {
    prop: {
      type: Array,
      default: () => [],
    },
    version: {
      type: Object,
      default: () => ({}),
    },
    isReleased: {
      type: Boolean,
    },
  },
  data: () => ({
    VALIDATION_REGEX,
    isFormValid: false,
  }),
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('workloadVersion.required'),
        pattern: (value) =>
          VALIDATION_REGEX.LETTER_NUMBER_DASH_AND_UNDERSCORE.test(value) ||
          this.$t('workloadVersion.networkInterfacePattern'),
        duplicate: (value) => {
          const found = this.prop.filter((element) => element.interface === value);
          if (found.length > 1) {
            return false || this.$t('workloadVersion.duplicateInterface');
          }
          return true;
        },
      };
    },
  },
  mounted() {
    this.isFormValid = this.validationForm();
  },
  updated() {
    this.isFormValid = this.validationForm();
  },
  methods: {
    addNewNetworkInterface() {
      this.$refs.networkInterfaceForm.$el.dispatchEvent(new Event('input'));
      const obj = {
        type: 'Bridged',
        interface: '',
        port_forwards: [],
      };
      this.prop.push(obj);
    },
    removeNetworkInterface(index) {
      this.prop.splice(index, 1);
      this.$refs.networkInterfaceForm.$el.dispatchEvent(new Event('input'));
    },
    validationForm() {
      return this.$refs.networkInterfaceForm ? this.$refs.networkInterfaceForm.validate() : false;
    },
    triggerSubmit() {
      if (this.isFormValid) {
        this.$emit('submit-event');
      }
    },
    receivePorts(payload) {
      this.prop.find((network) => network.type === 'NAT' && network.interface === payload.interface).port_forwards =
        payload.ports;
    },
    onSelectedInterfaceChange() {
      this.prop.forEach((network) => {
        if (Object.prototype.hasOwnProperty.call(network, 'port_forwards') && network.port_forwards.length === 0) {
          delete network.port_forwards;
        }
      });
      this.$refs.networkInterfaceForm.$el.dispatchEvent(new Event('input'));
    },
  },
};
</script>
