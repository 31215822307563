<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container
    v-if="version && type === 'docker-compose'"
    id="iiotBasicComposeContainer"
    class="mt-8 ml-4"
    :class="{ 'mr-0 pr-10': !isMarginVisible }"
    fluid
  >
    <v-form id="iiotBasicComposeForm" ref="basicComposeForm" data-cy="iiotBasicComposeForm">
      <v-row>
        <v-col v-resize="onResize" cols="12" lg="4" class="pl-0 pt-0" :class="{ 'pr-12': isMarginVisible }">
          <span class="basic-title">
            {{ $t('workloadVersion.versionSpecificInfo') }}
          </span>
          <v-text-field
            id="iiotBasicComposeName"
            v-model="version.name"
            data-cy="iiotBasicComposeName"
            :label="$t('workloadVersion.versionName')"
            :rules="[rules.required, rules.name_contains_only_spaces, rules.name_contains_control_character]"
            :maxlength="MAX_LENGTH_WORKLOAD_VERSION_NAME"
            autofocus
            validate-on-blur
            class="pt-6"
            :disabled="isReleased"
            @keydown.enter.prevent="triggerSubmit"
          />
          <upload-file-compose
            ref="versionUploadCompose"
            v-model="version.released"
            :version="version"
            :is-released="isReleased"
            class="pt-10 pb-6"
          />
          <div class="pt-7">
            <span>{{ $t('workloadVersion.selector') }}</span>
            <v-combobox
              id="iiotBasicComposeLabelsInput"
              ref="chips"
              v-model="selectedLabels"
              data-cy="iiotBasicComposeLabelsInput"
              :items="transformedLabels"
              :cache-items="false"
              :search-input.sync="search"
              :single-line="true"
              :multiple="true"
              :dense="true"
              :menu-props="{ bottom: true, offsetY: true }"
              :label="$t('addUpdateNode.labels')"
              class="font-size mt-2"
              flat
              hide-no-data
              hide-details
              hide-selected
              chips
              @input="search = null"
              @change="validateLabel"
              @keydown="triggerSubmitLabel"
            >
              <template #selection="data">
                <v-chip label small>
                  <span class="mb-2 px-2">
                    {{ data.item }}
                  </span>
                  <v-icon
                    id="iiotBasicComposeLabelsRemoveIcon"
                    data-cy="iiotBasicComposeLabelsRemoveIcon"
                    small
                    @click="removeChip(data.item)"
                  >
                    mdi-close
                  </v-icon>
                </v-chip>
              </template>
              <template #append-item>
                <div v-intersect="endIntersect" />
              </template>
              <v-tooltip slot="append" top>
                <template #activator="{ on }">
                  <v-icon
                    id="iiotBasicInfoIcon"
                    data-cy="iiotBasicInfoIcon"
                    class="cursor-help mt-3"
                    color="primary"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>
                <span>{{ $t('workloadVersion.labelInfo1') }}</span
                ><br />
                <span>{{ $t('nodes.addEditNode.labelInfo2') }}</span
                ><br />
                <span>{{ $t('nodes.addEditNode.labelInfo3') }}</span
                ><br />
                <span>{{ $t('nodes.addEditNode.labelInfo4') }}</span>
              </v-tooltip>
            </v-combobox>
          </div>
          <div class="iiotBasicComposeMarkAsRelease pr-14">
            <v-checkbox
              id="iiotBasicComposeMarkAsRelease"
              v-model="version.released"
              data-cy="iiotBasicComposeMarkAsRelease"
              :label="$t('workloadVersion.markAsReleased')"
              class="pt-5"
              :disabled="isReleased"
              @change="checkboxClicked"
            />
          </div>
        </v-col>
        <div v-if="isMarginVisible" class="pl-14">
          <v-divider vertical />
        </div>
        <v-col cols="12" lg="7" class="pt-0 pl-0" :class="{ 'pl-10': isMarginVisible }">
          <v-card
            v-if="version.composeFile && fileContent"
            id="iiotBasicComposeComposeFileContent"
            data-cy="iiotBasicComposeComposeFileContent"
            class="composeFileCard compose-scroll"
            :class="{ 'ml-16': isMarginVisible }"
            flat
          >
            <v-card-text class="composeFileContent text-left">
              <div>{{ fileContent }}</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import yaml2json from 'js-yaml';
import { MAX_LENGTH_WORKLOAD_VERSION_NAME, VALIDATION_REGEX, MAX_LENGTH_NAME } from '@/constants';
import UploadFileCompose from '@/components/workloads/dockerVmComponents/upload-file/UploadFileCompose.vue';

export default {
  components: {
    UploadFileCompose,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    version: {
      type: Object,
      default: () => ({}),
    },
    isReleased: {
      type: Boolean,
    },
  },
  data: () => ({
    MAX_LENGTH_WORKLOAD_VERSION_NAME,
    VALIDATION_REGEX,
    MAX_LENGTH_NAME,
    selectedLabels: [],
    transformedLabels: [],
    selectedChip: '',
    search: null,
    isMarginVisible: false,
    lastPageOfLabels: 1,
    params: {
      search: '',
      itemsPerPage: 10,
      page: 1,
    },
    flag: false,
    shouldSubmit: true,
  }),
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('workloadVersion.required'),
        name_contains_only_spaces: (value) =>
          !VALIDATION_REGEX.STRING_CONTAINS_ONLY_SPACES.test(value) ||
          this.$t('workloadDetail.wlNameContainOnlySpaces'),
        name_contains_control_character: (value) =>
          VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(value) || this.$t('workloadDetail.wlNameContainControlCharacter'),
      };
    },
    labelsList() {
      return this.$store.getters['labels/labelsList'];
    },
    count() {
      return this.$store.getters['labels/count'];
    },
    composeFile() {
      return this.version.composeFile ? this.version.composeFile.fileName : '';
    },
    fileContent() {
      const file = this.$store.getters['workloads/fileContent'];
      if (typeof file === 'object') {
        return file ? yaml2json.dump(file) : '';
      }
      return file;
    },
    getVolumeNameListCompose() {
      return this.$store.getters['workloads/getVolumeNameListCompose'];
    },
  },
  watch: {
    labelsList() {
      if (!this.version.selectors) {
        this.transformLabels();
      }
    },
    async selectedLabels(value) {
      value.forEach(async (el) => {
        const words = el.split(':');
        let label = this.checkId(words[0], words[1]);
        while (this.params.page <= this.lastPageOfLabels && !label) {
          this.params.page += 1;
          // eslint-disable-next-line no-await-in-loop
          await this.$store.dispatch('labels/fetchLabels', this.params);
          label = this.checkId(words[0], words[1]);
        }
        if (!label) {
          await this.removeChip(this.selectedChip);
          await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'workloadVersion.unknownSelector',
            color: 'red',
            showClose: true,
          });
        }
      });
      this.version.selectors = await this.transformLabelsToOriginal();
    },
    search(val) {
      if (val) {
        this.selectedChip = val;
        this.params.search = val;
        this.params.page = 1;
        this.$store.dispatch('labels/fetchLabels', this.params);
      }
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('labels/fetchLabels', this.params);
      this.lastPageOfLabels = Math.ceil(this.count / 10);
      this.transformLabels();
      this.$refs.versionUploadCompose?.$refs.uploadFileComposeForm?.$el.addEventListener('input', () => {
        this.version.workloadSpecificProperties.dockerConfigurationStorage.forEach((service) => {
          service.volumeName = '';
          service.restartOnConfigurationUpdate = false;
          service.containerPath = '';
        });
      });
    } catch (e) {
      this.$log.debug(e);
    }
  },
  methods: {
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        if (this.params.page >= this.lastPageOfLabels) {
          return;
        }
        this.params.page += 1;
        this.transformLabels();
      }
    },
    async removeChip(item) {
      const index = this.selectedLabels.indexOf(item);
      if (index >= 0) {
        this.selectedLabels.splice(index, 1);
      }
      this.$refs.basicComposeForm.$el.dispatchEvent(new Event('input'));
    },
    transformLabels() {
      // eslint-disable-next-line array-callback-return
      this.labelsList.map((el) => {
        this.transformedLabels.push(`${el.key}:${el.value}`);
      });
      if (this.version && this.version.selectors && this.version.selectors.length) {
        this.selectedLabels = [];
        // eslint-disable-next-line array-callback-return
        this.version.selectors.map((el) => {
          this.selectedLabels.push(`${el.key}:${el.value}`);
        });
      }
    },
    transformLabelsToOriginal() {
      const newSelectedLabels = [...this.selectedLabels];
      return newSelectedLabels.map((el) => {
        const words = el.split(':');
        const label = this.checkId(words[0], words[1]);
        return {
          _id: label?._id,
          key: words[0],
          value: words[1],
        };
      });
    },
    checkId(key, value) {
      return this.labelsList.find((label) => label.key === key && label.value === value);
    },
    async validateLabel(label) {
      const words = label[label.length - 1] ? label[label.length - 1].split(':') : this.selectedChip.split(':');
      this.$refs.basicComposeForm.$el.dispatchEvent(new Event('input'));
      if (this.transformedLabels.includes(label[label.length - 1]) || !label.length) {
        return;
      }
      if (!VALIDATION_REGEX.LABEL_REGEX.test(words[0])) {
        await this.showToast(this.selectedChip, 'invalidLabel');
      }
      if (this.labelsList.some((el) => el.key.toLowerCase() === words[0].toLowerCase())) {
        await this.showToast(label[label.length - 1], 'duplicateKey');
      }
      if (words.length !== 2 || words[0] === '' || words[1] === '') {
        await this.showToast(this.selectedChip, 'wrongFormatForLabel');
      }
    },
    async showToast(chip, text) {
      await this.removeChip(chip);
      await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: `nodes.addEditNode.${text}`,
        color: 'red',
        showClose: true,
      });
    },
    triggerSubmit() {
      if (this.$refs.basicComposeForm.validate()) {
        this.$emit('submit-event');
      }
    },
    triggerSubmitLabel(event) {
      if (event.key !== 'Enter') {
        this.shouldSubmit = false;
        return;
      }
      if (this.shouldSubmit && !this.search && this.$refs.basicComposeForm.validate()) {
        this.$emit('submit-event');
      }
      this.shouldSubmit = true;
    },
    onResize() {
      this.isMarginVisible = window.innerWidth > 1264;
    },
    checkboxClicked() {
      this.$refs.basicComposeForm.$el.dispatchEvent(new Event('input'));
    },
  },
};
</script>
<style scoped lang="scss">
.v-divider--vertical {
  min-height: 250px;
}
.iiotBasicComposeMarkAsRelease {
  max-width: 250px;
}
#iiotBasicComposeInfoIcon {
  padding-right: 2px;
}
.basic-title {
  font-size: 18px !important;
}
.composeFileCard {
  background-color: rgba(251, 252, 252, 1) !important;
  border: solid 1px #f0f3f5 !important;
  height: 55vh;
  width: fit-content;
  min-width: 25vw;
  max-width: 92%;
  max-height: 55vh;
  overflow-y: auto;
}
.composeFileContent {
  white-space: pre;
  color: black !important;
  font-size: 16px !important;
  max-width: inherit !important;
}
.compose-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.compose-scroll::-webkit-scrollbar-thumb {
  @extend .primary-tttech-background-btn;
}
.compose-scroll::-webkit-scrollbar-track {
  background: silver;
}
</style>
