<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container id="iiotDockerNetworksContainer" data-cy="iiotDockerNetworksContainer">
    <v-form id="iiotDockerNetworksForm" ref="dockerNetworkForm" data-cy="iiotDockerNetworksForm">
      <v-row v-for="(variable, index) in prop" :key="index">
        <v-col cols="11" class="pa-0">
          <v-row class="ma-0">
            <v-text-field
              :id="`iiotDockerNetworkNameInput_${index}`"
              v-model="prop[index]"
              :data-cy="`iiotDockerNetworkNameInput_${index}`"
              :label="$t('workloadVersion.networkName')"
              validate-on-blur
              :rules="[rules.required, rules.pattern, rules.duplicate]"
              :disabled="isReleased"
              :maxlength="MAX_LENGTH_DOCKER_NETWORK_NAME"
            />
          </v-row>
        </v-col>
        <v-col cols="1" class="mt-4 pa-0 pl-3">
          <v-btn
            v-if="prop.length > 1"
            :id="`iiotDockerNetworkRemoveButton_${index}`"
            :data-cy="`iiotDockerNetworkRemoveButton_${index}`"
            fab
            x-small
            dark
            class="primary-tttech-background-btn"
            :disabled="isReleased"
            @click="removeDockerNetwork(index)"
          >
            <v-icon dark> mdi-minus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="isFormValid">
        <div class="mb-1 ml-1">
          <v-btn
            id="iiotDockerNetworkNewButton"
            data-cy="iiotDockerNetworkNewButton"
            fab
            x-small
            dark
            class="primary-tttech-background-btn"
            :disabled="isReleased"
            @click="addNewDockerNetwork()"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </div>
        <span class="mt-1 ml-3">
          {{ $t('workloadVersion.dockerNetwork') }}
        </span>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { VALIDATION_REGEX, MAX_LENGTH_DOCKER_NETWORK_NAME } from '@/constants';

export default {
  props: {
    prop: {
      type: Array,
      default: () => [],
    },
    isReleased: {
      type: Boolean,
    },
    version: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    VALIDATION_REGEX,
    MAX_LENGTH_DOCKER_NETWORK_NAME,
    isFormValid: false,
  }),
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('workloadVersion.required'),
        pattern: (value) =>
          VALIDATION_REGEX.CONTAINER_NAME_PATTERN.test(value) || this.$t('workloadVersion.containerNetworkPattern'),
        duplicate: (value) => {
          const found = this.prop.filter((element) => element === value);
          if (found.length > 1) {
            return false || this.$t('workloadVersion.containerNetworkDuplicate');
          }
          return true;
        },
      };
    },
  },
  mounted() {
    this.isFormValid = this.validationForm();
  },
  updated() {
    this.isFormValid = this.validationForm();
  },
  methods: {
    addNewDockerNetwork() {
      this.$refs.dockerNetworkForm.$el.dispatchEvent(new Event('input'));
      this.prop.push('');
    },
    removeDockerNetwork(index) {
      this.prop.splice(index, 1);
      this.$refs.dockerNetworkForm.$el.dispatchEvent(new Event('input'));
    },
    validationForm() {
      return this.$refs.dockerNetworkForm ? this.$refs.dockerNetworkForm.validate() : false;
    },
  },
};
</script>
