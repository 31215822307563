var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"iiotUploadFileVm","data-cy":"iiotUploadFileVmContainer"}},[_c('v-form',{ref:"uploadFileVmForm",attrs:{"id":"iiotUploadFileVmForm","data-cy":"iiotUploadFileVmForm"}},[_c('v-col',[_c('v-row',[_c('span',[_vm._v(_vm._s(_vm.$t('workloadVersion.vmTitle')))])]),_c('v-row',[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"11"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c('v-text-field',{staticClass:"isRequired",attrs:{"id":"iiotUploadFileVmImageTextField","label":_vm.$t('workloadVersion.vmImageLabel'),"placeholder":_vm.$t('workloadVersion.imagePlaceholder'),"rules":[_vm.rules.required],"disabled":"","required":""},model:{value:(_vm.imageFile),callback:function ($$v) {_vm.imageFile=$$v},expression:"imageFile"}})],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.version.workloadProperties.snapshot.enabled === true ? _vm.$t('workloadVersion.snapshotEnabledVmTooltip') : _vm.$t('workloadVersion.snapshotDisabledVmTooltip'))+" ")])])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"id":"iiotUploadFileVmBtn","small":"","light":"","fab":"","color":"primary","disabled":_vm.isReleased},on:{"click":function($event){return _vm.$refs.uploadFileVmImageInput.click()}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"white--text"},on),[_vm._v(" arrow_upward ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('workloadVersion.uploadBtnTooltip')))])])],1),_c('input',{ref:"uploadFileVmImageInput",staticStyle:{"display":"none"},attrs:{"id":"iiotUploadFileVmImageInput","type":"file","accept":_vm.version.workloadProperties.snapshot.enabled === true
                ? _vm.allowedExtensionsSnaphotEnabledImage
                : _vm.allowedExtensionsSnaphotDisabledImage,"disabled":_vm.isReleased},on:{"click":_vm.clearInput,"change":function($event){return _vm.importFile(
                $event,
                'image',
                _vm.version.workloadProperties.snapshot.enabled === true
                  ? _vm.allowedExtensionsSnaphotEnabledImage
                  : _vm.allowedExtensionsSnaphotDisabledImage,
              )}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"id":"iiotUploadFileVmXmlTextField","label":_vm.$t('workloadVersion.vmXmlLabel'),"placeholder":_vm.$t('workloadVersion.xmlPlaceholder'),"rules":[_vm.rules.required],"disabled":"","required":""},model:{value:(_vm.xmlFile),callback:function ($$v) {_vm.xmlFile=$$v},expression:"xmlFile"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"id":"iiotUploadFileVmXmlBtn","light":"","small":"","fab":"","color":"primary","disabled":_vm.isReleased},on:{"click":function($event){return _vm.$refs.uploadFileVmXmlInput.click()}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"white--text"},on),[_vm._v(" arrow_upward ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('workloadVersion.uploadBtnTooltip')))])])],1),_c('input',{ref:"uploadFileVmXmlInput",staticStyle:{"display":"none"},attrs:{"id":"iiotUploadFileVmXmlInput","type":"file","accept":_vm.allowedExtensionsForVmXml,"disabled":_vm.isReleased},on:{"click":_vm.clearInput,"change":function($event){return _vm.importFile($event, 'xml', _vm.allowedExtensionsForVmXml)}}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }