<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container id="iiotPortsContainer" class="mt-2 mb-5">
    <v-form id="iiotPortsForm" ref="portsForm">
      <v-row v-for="(port, index) in prop" :key="index">
        <v-col cols="3" class="pa-0">
          <v-select
            :id="`iiotPortsSelect_${index}`"
            v-model="port.protocol"
            :data-cy="`iiotPortsSelect_${index}`"
            :items="[
              { value: 'TCP', text: $t('workloadVersion.ports.tcp') },
              { value: 'UDP', text: $t('workloadVersion.ports.udp') },
            ]"
            attach
            :label="$t('workloadVersion.ports.protocol')"
            :disabled="isReleased"
            @change="protocolHasChanged"
          />
        </v-col>
        <v-col cols="4" class="pa-0 pl-4">
          <v-row class="ma-0">
            <v-text-field
              :id="`iiotPortsHostInput_${index}`"
              v-model.number="port.host_port"
              :label="$t('workloadVersion.ports.hostPort')"
              validate-on-blur
              :rules="[rules.required, rules.pattern, rules.duplicateHost(port)]"
              :disabled="isReleased"
              @keydown.enter.prevent="triggerSubmit"
            />
          </v-row>
        </v-col>
        <v-row justify="center" class="pt-8 pl-4">
          <span>:</span>
        </v-row>
        <v-col cols="4" class="pa-0 pl-4">
          <v-text-field
            :id="`iiotPortsContainerInput_${index}`"
            v-model.number="port.container_port"
            :label="type === 'vm' ? $t('workloadVersion.ports.guestPort') : $t('workloadVersion.ports.containerPort')"
            validate-on-blur
            :rules="[rules.required, rules.pattern, rules.duplicateContainer(port)]"
            :disabled="isReleased"
            @keydown.enter.prevent="triggerSubmit"
          />
        </v-col>
        <v-col cols="1" class="mt-4 pl-3 pa-0">
          <v-btn
            :id="`iiotPortsRemoveButton_${index}`"
            fab
            x-small
            dark
            class="primary-tttech-background-btn"
            :disabled="isReleased"
            @click="removePort(index)"
          >
            <v-icon dark> mdi-minus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="isFormValid">
        <div class="mb-1 ml-1">
          <v-btn
            id="iiotPortsNewButton"
            fab
            x-small
            dark
            class="primary-tttech-background-btn"
            :disabled="isReleased"
            @click="addNewPort()"
          >
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </div>
        <span class="mt-1 ml-3">
          {{ $t('workloadVersion.ports.newPort') }}
        </span>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { VALIDATION_REGEX } from '@/constants';

export default {
  props: {
    prop: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
    interface: {
      type: String,
      default: '',
    },
    isReleased: {
      type: Boolean,
    },
    version: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    VALIDATION_REGEX,
    isFormValid: false,
  }),
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('workloadVersion.required'),
        pattern: (value) => VALIDATION_REGEX.PORT_RANGE.test(value) || this.$t('workloadVersion.ports.portPattern'),
        duplicateHost: (port) => {
          const found = this.prop.filter(
            (element) => element.host_port === port.host_port && element.protocol === port.protocol,
          );
          if (found.length > 1) {
            return false || this.$t('workloadVersion.ports.duplicatePort');
          }
          return true;
        },
        duplicateContainer: (port) => {
          const found = this.prop.filter(
            (element) => element.container_port === port.container_port && element.protocol === port.protocol,
          );

          if (found.length > 1) {
            return false || this.$t('workloadVersion.ports.duplicatePort');
          }
          return true;
        },
      };
    },
  },
  mounted() {
    this.isFormValid = this.validationForm();
  },
  updated() {
    this.isFormValid = this.validationForm();
    if (this.isFormValid) {
      this.$emit('ports-event', { ports: this.prop, interface: this.interface });
    }
  },
  methods: {
    addNewPort() {
      this.$refs.portsForm.$el.dispatchEvent(new Event('input'));
      const obj = {
        protocol: 'TCP',
        host_port: '',
        container_port: '',
      };
      this.prop.push(obj);
    },
    removePort(index) {
      this.prop.splice(index, 1);
      this.$refs.portsForm.$el.dispatchEvent(new Event('input'));
    },
    validationForm() {
      return this.$refs.portsForm ? this.$refs.portsForm.validate() : false;
    },
    triggerSubmit() {
      if (this.isFormValid) {
        this.$emit('submit-event');
      }
    },
    protocolHasChanged() {
      this.$refs.portsForm.$el.dispatchEvent(new Event('input'));
    },
  },
};
</script>
